import { useStaticQuery, graphql } from "gatsby"
export const useOpenrolesEN = () => {
  const { allMdx } = useStaticQuery(
    graphql`
      query {
        allMdx(
          filter: {
            fields: { sourceInstanceName: { eq: "jobs" }, locale: { eq: "en" } }
            frontmatter: { date: { ne: null }, status: { eq: "published" } }
          }
          sort: { fields: [frontmatter___date], order: DESC }
        ) {
          edges {
            node {
              id
              body
              frontmatter {
                title
                tags
                date
                location
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `,
  )
  return allMdx
}
