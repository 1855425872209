import React from "react"

import PageWrapper from "../components/PageWrapper"
import SEO from "../components/SEO"
//import Content from "../sections/career/Content";
//import Feature from "../sections/career/Feature";
import Roles from "../sections/career/Roles"
import Benefits from "../sections/career/Benefits"
import Hero from "../sections/career/Hero"
import { t } from "@lingui/macro"

const Career = ({ location }) => {
  return (
    <>
      <PageWrapper headerDark>
        <SEO
          pathname={location.pathname}
          title={t`Karriere - Werde AI Expert`}
          description={t`Wir sind Experten für Künstliche Intelligenz, Machine Learning und Deep Learning. Für spannende Projekte suchen wir Unterstützung und bieten attraktive Jobs mit Start-up-Spirit.`}
        />
        <Hero />
        <Benefits />
        {/*}
        <Content />
        <Feature />
        */}
        <Roles />
      </PageWrapper>
    </>
  )
}
export default Career
