import React from "react"
import { Container, Row, Col } from "react-bootstrap"
import { t, Trans } from "@lingui/macro"

import { Section, Title } from "../../components/Core"

import undraw_game_day from "../../assets/image/svg/undraw_game_day_ucx9.svg"
import undraw_version_control from "../../assets/image/svg/undraw_version_control_9bpv.svg"
import undraw_workation from "../../assets/image/svg/undraw_working_from_anywhere_re_9obt_edit.svg"
import undraw_teaching from "../../assets/image/svg/undraw_teaching_f1cm.svg"
import undraw_hang_out from "../../assets/image/svg/undraw_hang_out_h9ud.svg"
import undraw_tasting from "../../assets/image/svg/undraw_tasting_de22.svg"

const BenefitCard = ({ image, title, description, children, ...rest }) => (
  <div css={{ textAlign: "center" }}>
    <img
      src={image}
      alt={title}
      css={{ maxHeight: 250, width: "100%" }}
      className="mb-5"
    />
    <h4>{title}</h4>
    <p>{children}</p>
  </div>
)

const Benefits = () => (
  <>
    <Section>
      <Container>
        <Row>
          <Col lg="12" className="mb-5 text-center">
            <Title>{t`Mit Spirit und Teamgeist gemeinsam erfolgreich!`}</Title>
          </Col>
          <Col md="6" xl="4" className="mb-5">
            <BenefitCard
              title={t`Team aus Gleichgesinnten`}
              image={undraw_game_day}
            >
              <Trans>
                Wir teilen das gemeinsame Ziel, Probleme zu lösen, die die Welt
                verändern. Sei Teil von unserem motivierten, talentierten, dynamischen Team.
              </Trans>
            </BenefitCard>
          </Col>
          <Col md="6" xl="4" className="mb-5">
            <BenefitCard title={t`Mentoring`} image={undraw_version_control}>
              <Trans>
                Arbeite in einer großartigen Entwicklungskultur und lerne von
                Kolleg:innen mit beeindruckendem Hintergrund.
              </Trans>
            </BenefitCard>
          </Col>
          <Col md="6" xl="4" className="mb-5">
            <BenefitCard
              title={t`Workation`}
              image={undraw_workation}
            >
              <Trans>
                Du möchtest beim Entwickeln frische Bergluft oder sonnige Küsten genießen? Kein Problem! Pro Jahr kannst du bei uns bis zu 2 x 3 Monate auf Workation im EU-Ausland verbringen.
              </Trans>
            </BenefitCard>
          </Col>
          <Col md="6" xl="4" className="mb-5">
            <BenefitCard
              title={t`Personal Development`}
              image={undraw_teaching}
            >
              <Trans>
                Wir fördern Dich durch eine individuelle, klare Weiterentwicklungs-Roadmap
                sowie unterstützen das Besuchen von Meetups und Communities.
              </Trans>
            </BenefitCard>
          </Col>
          <Col md="6" xl="4" className="mb-5">
            <BenefitCard title={t`Kaffee-Kultur`} image={undraw_hang_out}>
              <Trans>
                Wir lieben unseren Kaffee und das damit verbundene Get-Together.
              </Trans>
            </BenefitCard>
          </Col>
          <Col md="6" xl="4" className="mb-5">
            <BenefitCard
              title={t`Unbegrenzte Früchte und Snacks`}
              image={undraw_tasting}
            >
              <Trans>
                Sei immer auf dem höchsten Level – unsere Früchte- und Snack-Bar
                gibt Dir den nötigen Smooth.
              </Trans>
            </BenefitCard>
          </Col>
        </Row>
      </Container>
    </Section>
  </>
)

export default Benefits
